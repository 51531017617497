import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography
} from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HealthNowCardFront from "../assets/cards/HealthNowCardFront.png";
import { Card, CardStatus } from "../models";
import { CardDesign, CardDesignTextStyle } from "../models/cards/cardDesign";
import { callApi, fetchData } from "../utils/fetchData";
import { useConfig } from "../utils/useConfig";
import { useData } from "../utils/useData";
import { SectionHeader } from "./SectionHeader";

export const CardActions: FC<{ card: Card }> = ({ card }) => {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { userInfoData, refetch } = useData();
  const { getToken } = useKindeAuth();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [error, setError] = useState("");

  const mutation = useMutation({
    mutationFn: async (card: Card) => {
      const blockCard = card.status === CardStatus.ACTIVE;

      const response = await callApi(
        `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${card.id}/block`,
        "PUT",
        {
          blockOption: blockCard ? "BLOCK" : "UNBLOCK"
        },
        getToken
      );

      if (response && !response.ok)
        throw new Error(
          `There was a problem ${
            blockCard ? "Freezing" : "Un-Freezing"
          } the card`
        );

      return card;
    },
    onSuccess: (card: Card) => {
      refetch();
      setIsConfirmationModalOpen(false);
      toast.success(
        `Card successfully ${
          card.status === CardStatus.ACTIVE ? "frozen" : "un-frozen"
        }`
      );
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const cardDesign = useQuery<CardDesign>(
    `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${card.id}/design`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${card.id}/design`,
        getToken
      ),
    {
      enabled: !!card.id || !!userInfoData?.employerId
    }
  );

  if (!card) return null;

  return (
    <>
      <SectionHeader text="Your card" />
      <MuiCard variant="outlined" sx={{ maxWidth: "400px", mx: "auto" }}>
        <Box mx="auto" position="relative">
          <CardMedia
            component="img"
            width="100%"
            src={
              card?.cardDesignId && config?.PUBLIC_BUCKET_URL
                ? `${config.PUBLIC_BUCKET_URL}/card-designs/${card.cardDesignId}/normal.png`
                : HealthNowCardFront
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = HealthNowCardFront;
            }}
            alt="HealthNow card"
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "45px",
              left: "15px"
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                color:
                  cardDesign.data?.textStyle === CardDesignTextStyle.BLACK
                    ? "black"
                    : "white",
                textShadow:
                  cardDesign.data?.textStyle === CardDesignTextStyle.BLACK
                    ? "1px 1px 2px white"
                    : "1px 1px 2px black"
              }}
            >
              {card?.maskedCardNumber}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                color:
                  cardDesign.data?.textStyle === CardDesignTextStyle.BLACK
                    ? "black"
                    : "white",
                textShadow:
                  cardDesign.data?.textStyle === CardDesignTextStyle.BLACK
                    ? "1px 1px 2px white"
                    : "1px 1px 2px black"
              }}
            >
              <Typography>
                {card.expiry.slice(2, 4)}/{card.expiry.slice(0, 2)}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={1} p={1}>
          {card.status === CardStatus.ACTIVE && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/reset-pin")}
              >
                Reset Pin
              </Button>
            </>
          )}

          {(card.status === CardStatus.ACTIVE ||
            card.status === CardStatus.INACTIVE) && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              {card.status === CardStatus.ACTIVE
                ? "Freeze card"
                : "Un-Freeze Card"}
            </Button>
          )}

          {(card.status === CardStatus.CREATED ||
            card.status === CardStatus.ISSUED) && (
            <Button
              component={Link}
              to="/activate-card"
              variant="outlined"
              color="secondary"
            >
              Activate card
            </Button>
          )}
        </Box>
      </MuiCard>

      <Dialog
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <DialogTitle>
          {card.status === CardStatus.ACTIVE ? "Freeze card" : "Un-Freeze Card"}
        </DialogTitle>
        <DialogContent>
          {error && <Typography color="error">{error}</Typography>}
          <DialogContentText>
            {card.status === CardStatus.ACTIVE
              ? "Are you sure you want to freeze your card? You will be unable to use it."
              : "Are you sure you want to un-freeze your card?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmationModalOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            onClick={() => mutation.mutate(card)}
            autoFocus
            variant="contained"
            color="error"
          >
            {card.status === CardStatus.ACTIVE ? "Freeze card" : "Un-Freeze Card"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
