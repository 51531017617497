import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Grid,
  Card as MuiCard,
  Typography
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { AccountsList } from "../../components/AccountsList";
import { CardActions } from "../../components/CardActions";
import { CommunicationsBanners } from "../../components/CommunicationsBanners";
import { Kyc } from "../../components/KYC";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { LocalCommunicationBanner } from "../../components/LocalCommunicationBanner";
import { PersonalDetailsForm } from "../../components/PersonalDetailsForm";
import { SectionHeader } from "../../components/SectionHeader";
import { TransactionList } from "../../components/TransactionList";
import {
  Card,
  CardStatus,
  CardStatusResponse,
  KycStatus,
  UserInfo
} from "../../models";
import { useData } from "../../utils/useData";

export const Home: FC<{ userInfoData: UserInfo }> = ({ userInfoData }) => {
  const { employer, cards, transactions, employee, accounts, getCardStatus } =
    useData();

  const [primaryCard, setPrimaryCard] = useState<Card>();

  const [promptForActivation, setPromptForActivation] = useState(false);
  const [promptConfirmDetails, setPromptConfirmDetails] = useState(false);
  const [promptForKyc, setPromptForKyc] = useSessionStorage(
    `${userInfoData.employeeId}-prompt-for-kyc`,
    false
  );
  const [showCardActivatedSuccess, setShowActivatedSuccess] = useSessionStorage(
    `${userInfoData.employeeId}-show-card-activated-success`,
    false
  );
  const [showCardSuccess, setShowCardSuccess] = useSessionStorage(
    `${userInfoData.employeeId}-show-card-success`,
    false
  );

  // Set their primary card, to the first card found that is not "REMOVED"
  const handleCardState = async () => {
    const foundCard = cards.data?.items.filter(
      (c) => c.status !== CardStatus.REMOVED
    )[0];

    if (!foundCard) return;
    setPrimaryCard(foundCard);

    if (
      foundCard.status === CardStatus.CREATED ||
      foundCard.status === CardStatus.ISSUED
    ) {
      const { isReadyForActivation }: CardStatusResponse = await getCardStatus(
        foundCard?.id
      );
      setPromptForActivation(isReadyForActivation);
    }
  };

  useEffect(() => {
    handleCardState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards.isLoading, cards.data?.items]);

  useEffect(() => {
    if (!employee.isLoading && !employee.error) {
      setPromptConfirmDetails(
        !employee.data?.contact.address.address1 ||
          !employee.data.cardDisplayName
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.data]);

  // Only update promptForKyc if the DB value has changed
  // User completes KYC
  // We assume success (setPromptForKyc(false))
  // If [employee], then on re-render will update back to false if KYC webhook updating the DB hasn't finished
  // [employee.data.stats.kyc.status] means that even on re-render after onSuccess when we fetch latest employee obj
  // we won't "overwrite" the local assumed success promptForKyc and set it back to true.
  useEffect(() => {
    if (
      !employee.isLoading &&
      !employee.error &&
      !employer.isLoading &&
      !employer.error &&
      employer.data?.generalOptions.requireKyc // Only push the user through KYC if its required by their employer
    ) {
      setPromptForKyc(
        !(employee.data?.stats?.kyc?.status === KycStatus.APPROVED)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.data?.stats?.kyc?.status]);

  if (
    cards.isLoading ||
    cards.isIdle ||
    employee.isLoading ||
    employee.isIdle ||
    accounts.isLoading
  ) {
    return <LoadingSpinner />;
  }

  if (cards.error || employee.error) {
    return (
      <Typography>Something went wrong, please try again later</Typography>
    );
  }

  if (promptConfirmDetails)
    return (
      <>
        <MuiCard sx={{ mb: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6">Please confirm your details</Typography>
            }
            sx={{ pb: 0 }}
          />
          <CardContent>
            <Typography color="grey">
              Some of your details have been provided by your employer. Please
              check they are correct and update them.
            </Typography>
          </CardContent>
        </MuiCard>
        <PersonalDetailsForm
          showCancel={false}
          submitButtonText="Confirm"
          onSuccess={() => {
            setPromptConfirmDetails(false);
            if (!employer.data?.generalOptions.requireKyc) {
              setShowCardSuccess(true);
            }
          }}
          termsAndConditions
        />
      </>
    );

  if (promptForKyc)
    return (
      <Kyc
        userInfoData={userInfoData}
        onSuccess={() => {
          setPromptForKyc(false);
          setShowCardSuccess(true);
        }}
      />
    );

  return (
    <Grid container rowSpacing={3} direction="column">
      {/* Only during the session in which they complete KYC, we show a success message */}
      {showCardSuccess && (
        <LocalCommunicationBanner
          title="Great - your card is on its way"
          description="We've verified your identity and your card has been ordered. It
        should be with your employer within the next 1-2 weeks. Once
        received you'll need to activate your card, here, through your
        HealthNow account."
          onClose={() => setShowCardSuccess(false)}
        />
      )}

      {/* At any point during their time on the platform, if their card requires activation, then prompt them */}
      {promptForActivation && (
        <LocalCommunicationBanner
          title="Almost there"
          description="Your card needs to be activated before you can use it"
        >
          <Box display="flex" p={1}>
            <Link to="activate-card" style={{ marginLeft: "auto" }}>
              <Button>Activate card</Button>
            </Link>
          </Box>
        </LocalCommunicationBanner>
      )}

      {/* Only during the session in which they complete their card activation, do we show a success message */}
      {showCardActivatedSuccess && (
        <LocalCommunicationBanner
          title="Congratulations, your card is active!"
          description="Please see the categories (below) where your card can be used."
          onClose={() => setShowActivatedSuccess(false)}
        />
      )}

      {/* Dynamic Communications from API/DB */}
      <CommunicationsBanners />

      {(accounts.data?.items.filter((a) => !a.deletedAt).length ?? 0) > 0 && (
        <Grid item>
          <AccountsList
            accounts={accounts.data?.items.filter((a) => !a.deletedAt) ?? []}
          />
        </Grid>
      )}

      <Grid item>
        <SectionHeader text="Recent transactions" />
        <TransactionList
          transactions={transactions.data?.items ?? []}
          limit={5}
        />
      </Grid>

      {/* Only show the card if its ACTIVE/INACTIVE... Or if its CREATED and is also ready for activation */}
      {primaryCard &&
        ((primaryCard.status !== CardStatus.CREATED &&
          primaryCard.status !== CardStatus.ISSUED) ||
          ((primaryCard.status === CardStatus.CREATED ||
            primaryCard.status !== CardStatus.ISSUED) &&
            promptForActivation)) && (
          <Grid item>
            <CardActions card={primaryCard} />
          </Grid>
        )}
    </Grid>
  );
};
